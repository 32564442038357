
import { Tab, Tabs, Box } from '@mui/material';
import { useState } from 'react';

import './App.css';
import SearchByMultipleParams from './components/SearchByMultipleParams';
import SearchByOneParam from './components/SearchByOneParam';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function App() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className='headline'>
        <h1>Hallo VBM</h1>
      </div>

      <div className='tabFrame'>
        <div className='tabs'>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: "red", height: 0 } }}
          >
            <Tab label="Direkter Download" />
            <Tab label="Erweiterte Suche" />
          </Tabs>
        </div>

        <TabPanel value={value} index={0}>
          <SearchByOneParam />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SearchByMultipleParams />
        </TabPanel>
      </div>
    </div>
  );
}

export default App;

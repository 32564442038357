import { useState } from 'react';

import {
    Box,
    TextField,
    Button
} from '@mui/material'

import apiBaseUrl from "../api"
import ResultList from './ResultList';

const SearchByMultipleParams = () => {

    const [ref, setRef] = useState('');
    const [lot, setLot] = useState('');
    const [gtin, setGtin] = useState('');
    const [resultList, setResultList] = useState();

    const submit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(apiBaseUrl + `/multiSearch/?ref=${ref}&lot=${lot}&gtin=${gtin}`, {
                method: "GET",
                mode: "cors"
            })

            const result = await response.json()
            setResultList(result)
            console.log(result);

        } catch (error) {

        }
    }

    return (
        <>
            <div className="mainFrame">
                <form>
                    <Box
                        sx={
                            {
                                '& fieldset': {
                                    borderRadius: '0px'
                                }
                            }
                        }>


                        <TextField
                            id='search-param-ref'
                            label='Ref'
                            variant='outlined'
                            size='small'
                            fullWidth
                            margin='dense'
                            value={ref}
                            onChange={(e) => setRef(e.target.value)}
                        />

                        <TextField
                            id='search-param-lot'
                            label='Lot'
                            variant='outlined'
                            size='small'
                            fullWidth
                            margin='dense'
                            value={lot}
                            onChange={(e) => setLot(e.target.value)}
                        />

                        <TextField
                            id='search-param-gtin'
                            label='Gtin'
                            variant='outlined'
                            size='small'
                            fullWidth
                            margin='dense'
                            value={gtin}
                            onChange={(e) => setGtin(e.target.value)}
                        />

                        <div className="mainButton">
                            <Button
                                onClick={submit}
                                variant='contained'
                                fullWidth
                                sx={{
                                    borderRadius: '0px'
                                }}
                            >
                                Search
                            </Button>
                        </div>

                    </Box>
                </form>
                {/* </Box> */}
            </div>

            <div className="resultFrame">
                {resultList && <ResultList result={resultList} />}
            </div>
        </>
    );
}

export default SearchByMultipleParams;
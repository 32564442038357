
import { margin } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'ref', headerName: 'REF', minWidth: 130, flex: 1 },
    { field: 'lot', headerName: 'LOT', minWidth: 130, flex: 1 },
    { field: 'gtin', headerName: 'GTIN', minWidth: 130, flex: 1 },
    {
        field: 'path',
        headerName: '',
        width: 130,
        flex: 2,
        sortable: false,
        renderCell: (params) => <a target='_blank' href={`${params.row.path}`}>DOWNLOAD</a>
    }
];
const ResultList = (props) => {


    let rows = props.result.map((item) => ({
        id: item._id,
        ref: item.refNo,
        lot: item.lotNo,
        gtin: item.gtin,
        path: item.path
    }))

    return (
        <div style={{ height: 400, width: '98%', padding: '10px' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                hideFooterPagination
                hideFooter
                disableColumnMenu={true}
                sx={{
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    borderRadius: '0px'
                }}
            />
        </div>
    );
}

export default ResultList;
// const apiBaseUrl =
//     process.env.NODE_ENV === 'production'
//         ? process.env.REACT_APP_BACKEND_URL
//         : "http://localhost:9000"


// const apiBaseUrl = "http://localhost:9000"
// const apiBaseUrl = "https://vbmifuarchiv.herokuapp.com"
const apiBaseUrl = "https://www.vbm.cfpg.de"


export default apiBaseUrl;
